import React from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Headers from './Component/Header';
import Footer from './Component/Footer';
import carpet from './Component/product/carpet.jpeg';
import hardwood from './Component/product/hardwood.png';
import glasstile from './Component/product/glasstile.png';
import laminate from './Component/product/laminate.png';
import luxuryvinyl from './Component/product/luxuryvinyl.png';
import Tile from './Component/product/Tile.png';
import vinyl from './Component/product/vinyl.png';
import waterproof from './Component/product/waterproof.png';
import { useNavigate } from 'react-router-dom';
import s2 from './Component/s2.png';

function Product() {
  const Carpet = 'carpet';
  const Glasstile = 'glasstile';
  const Furniture = 'furniture';
  const Hardwood = 'hardwood';
  const Metaltile = 'metaltile';
  const Luxuryvinyl = 'luxuryvinyl';
  const Vinyl = 'vinyl';
  const TileS = 'Tile';
  const Naturalstone = 'naturalstone';

  const navigate = useNavigate();
  const handlepropertyselect = (id) => {
    navigate(`/products/${id}`);
}
  return (
    <div>
      <div><Headers /> </div>
      <div className='container w-full'>
        <div className='text-center  p-4' >
          <span className='font-bold'>Shopping for new floors in the Cassville, MO area?</span> <br />
          <small className='justify'>BROWSE THE MOST EXTENSIVE LOCAL ONLINE FLOORING CATALOG</small>
        </div>
        <section className=' row flex  item-center justify-center'>
          <div className='col-6 col-md-3 d-flex items-center justify-content-center p-3 row '>
          <button onClick={() => handlepropertyselect(Carpet)}> <img src={carpet} alt="Shop for Carpet in Claysburg, PA from Carpet Depot Home Center" title='Shop for Carpet in Claysburg, PA from Carpet Depot Home Center' class="d-block"  /></button>
            
            <p className='d-flex justify-content-center  font-bold' style={{color:'#803333'}}>Carpet </p>
          </div>
          <div className='col-6 col-md-3 d-flex items-center justify-content-center p-3 row'>
            <button onClick={() => handlepropertyselect(Hardwood)} ><img src={hardwood} alt="Los Angeles" title='Shop for HardWood in Claysburg, PA from Carpet Depot Home Center' class="d-block" />   </button>
            <p className='d-flex justify-content-center text-[#803333] font-bold' style={{color:'#803333'}}>HardWood</p>
          </div>
          <div className='col-6 col-md-3 d-flex items-center justify-content-center p-3 row'>
            <button onClick={() => handlepropertyselect(Metaltile)}  ><img src={laminate} alt="Los Angeles" title='Shop for Laminate in Claysburg, PA from Carpet Depot Home Center'  class="d-block" />   </button>
            <p className='d-flex justify-content-center text-[#803333] font-bold' style={{color:'#803333'}}>Metaltile</p>
          </div>
          <div className='col-6 col-md-3 d-flex justify-content-center p-3 row'>
            <button onClick={() => handlepropertyselect(TileS)} ><img src={Tile} alt="Los Angeles" title='Shop for Tile in Claysburg, PA from Carpet Depot Home Center' class="d-block" />   </button>
            <p className='d-flex justify-content-center text-[#803333] font-bold' style={{color:'#803333'}}>Tile</p>
          </div>
          <div className='col-6 col-md-3 d-flex justify-content-center p-3 row'>
            <button onClick={() => handlepropertyselect(Luxuryvinyl)} ><img src={luxuryvinyl} alt="Los Angeles" title='Shop for Luxury Vinyl in Claysburg, PA from Carpet Depot Home Center'  class="d-block" />   </button>
            <p className='d-flex justify-content-center text-[#803333] font-bold'style={{color:'#803333'}} >Luxury Vinyl</p>
          </div>
          <div className='col-6 col-md-3 d-flex justify-content-center p-3 row'>
            <button onClick={() => handlepropertyselect(Naturalstone)}  ><img src={waterproof} alt="Los Angeles" title='Shop for WaterProof in Claysburg, PA from Carpet Depot Home Center' class="d-block" />   </button>
            <p className='d-flex justify-content-center text-[#803333] font-bold' style={{color:'#803333'}} >Natural Stone</p>
          </div>
          <div className='col-6 col-md-3 d-flex justify-content-center p-3 row'>
            <button onClick={() => handlepropertyselect(Vinyl)} ><img src={vinyl} alt="Los Angeles" title='Shop for Vinyl in Claysburg, PA from Carpet Depot Home Center' class="d-block" />   </button>
            <p className='d-flex justify-content-center text-[#803333] font-bold' style={{color:'#803333'}}>Vinyl</p>
          </div>
          <div className='col-6 col-md-3 d-flex justify-content-center p-3 row'>
          <button onClick={() => handlepropertyselect(Glasstile)}> <img src={glasstile} alt="Shop for Carpet in Claysburg, PA from Carpet Depot Home Center" title='Shop for Carpet in Claysburg, PA from Carpet Depot Home Center' class="d-block"  /></button>
            <p className='d-flex justify-content-center text-[#803333] font-bold' style={{color:'#803333'}}>Glass Tile</p>
          </div>
          {/* <div className='col-6 col-md-3 d-flex justify-content-center p-3 row'>
            <button onClick={() => handlepropertyselect(Furniture)} ><img src={furniture} alt="Los Angeles" title='Shop for Furniture in Claysburg, PA from Carpet Depot Home Center' class="d-block" />   </button>
            <p className='d-flex justify-content-center text-[#803333] font-bold' style={{color:'#803333'}}>Furniture</p>
          </div>
          <div className='col-6 col-md-3 d-flex justify-content-center p-3 row'>
            <a href='/products/hardware' ><img src={hardware} alt="Los Angeles" title='Shop for Hardware in Claysburg, PA from Carpet Depot Home Center' class="d-block" />   </a>
            <p className='d-flex justify-content-center text-[#803333] font-bold' style={{color:'#803333'}}>Hardware</p>
          </div> */}

        </section >
        <a href='/financing' ><img src={s2} alt="Los Angeles" class="d-block rounded p-4" /></a>
      </div>
      <div><Footer /> </div>
    </div>
  )
}

export default Product
