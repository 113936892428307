import React from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Headers from './Component/Header';
import Footer from './Component/Footer';
import Request from './Component/requestEstimate';
import about from './Component/about.png';
import brand1 from './Component/home/brand1.png';
import brand2 from './Component/home/brand2.png';

function About() {
  return (
    <div>
      <div><Headers /> </div>
      <div className='container p-4'>
        <div className='text-center font-bold p-4'>
          <span style={{ fontSize: '35px' }}>About Nickle's Flooring</span> <br />
          <small className='justify'>GET TO KNOW YOUR CASSVILLE, MO AREA FLOORING EXPERTS</small>
        </div>
        <section className='container'>
          <div class="row" style={{ paddingTop: '100px' }}>
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <img src={about} alt="Los Angeles" class="d-block" style={{ borderRadius: '10%' }} />
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6" style={{
              paddingTop: '30px ', paddingLeft: '80px', paddingRight: '80px', paddingInline: '5% 10%'
            }}>
              <div className='jumbotron' >
                <p class="lead font-bold">Serving you and your home for long time</p>
                <div className='row'>
                  <div class="col-md-12">
                    <div class="card-box-c foo" style={{ border: '2px solid #A9B6D3', borderRadius: '15px', padding: '20px' }}>
                      <div class="card-body-c align-self-center">
                        <p class="content-c">
                          Being a family-owned and operated business, we’re able to offer you that personal touch you’ve been looking for. Our goal is to always make all of our customers happy, and we believe in treating each customer like a part of our family.
                          <br/>
                          We look forward to hearing from you soon! And when you’re in Cassville we hope you come in and visit.
                          <br/>
                          Builders & Contractors: We have a special program in place for your new home construction and full home remodels. Call us today for details!  </p>                    <p class="lead">
                          <a href="/contact" class="btn btn-danger btn-lg active" role="button" style={{ borderRadius: '15px' }}>Contact Us</a>
                        </p>
                      </div>
                    </div>
                  </div>

                </div>
                <br></br>
              </div>

            </div>
          </div>
        </section >
      </div>
      <section class="p-5"style={{backgroundColor:'#803333'}}>
        <div class="row">
          <div class="col-md-3 ">

          </div>
          <div class="col-md-6 b">
            <div class="card-box-c foo p-5 bg-black text-white" style={{ borderRadius: '15px' }}>
              <div class="card-header-c d-flex">
                <div class="card-box-ico">
                  <span ><i class="fa-sharp fa fa-house"></i></span>
                </div>
                <div class="card-title-c">
                  <h2 class=" text-center"> Serving you and your home</h2>
                </div>
              </div>
              <div class="card-body-c align-self-center">
                <p class="content-c p-2">
                  Check out our catalog
                </p>
                <a href='/products'> <div className='btn btn-danger w-100'>Browse Our Catalog</div> </a>
              </div>
            </div>

          </div>


        </div>
      </section>
      <div><Request /></div>

      <section className='p-4 container border-t-2 py-2'>
        <div className='text-center font-bold'>
          <span style={{ fontSize: '25px' }}>Featured brands & technologies</span>
        </div>
        <section>
					<div >
							<img
								src={brand1}
								alt="brands"
								className="mx-auto "
							/>
						</div>
						<div >
							<img
								src={brand2}
								alt="brands"
								className="mx-auto"
							/>
						</div>
			</section>

      </section>
      <div><Footer /> </div>
    </div>
  )
}

export default About
