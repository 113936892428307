import React from 'react';
import myImage from './home/logo.png';
import myImage1 from './sfinancials.png';
import myImage2 from './mastercard.png';
import myImage3 from './visa.png';
import myImage4 from './americanexpress.png';
import myImage5 from './discover.png';

function Footer() {
  return (
    
    <div className="px-4 pt-16 lg:px-8 bg-[#803333] text-white border-t">
      <div className="grid gap-10 row-gap-6 mb-8 sm:grid-cols-2 lg:grid-cols-4 container mx-auto">
        <div className="sm:col-span-2">
          <a href="/" className="block" style={{ height: '35%', width: '70%' }}>
            <img src={myImage} alt="Los Angeles" />
          </a>
          <div className="mt-6 lg:max-w-sm">
            <p className="text-sm text-white">
            470 State Highway 76 Cassville, MO 65625 <br />
              <a href="tel:+18144585890" className='text-white'>
                <i className="bi bi-telephone">(417) 847-2484</i>
              </a>
            </p>
            <div className='flex space-x-2 mt-3'>
              <img src={myImage1} className='rounded' alt="Los Angeles" style={{ height: '30px' }} />
              <img src={myImage2} className='rounded' alt="Los Angeles" style={{ height: '30px' }} />
              <img src={myImage3} className='rounded' alt="Los Angeles" style={{ height: '30px' }} />
              <img src={myImage4} className='rounded' alt="Los Angeles" style={{ height: '30px' }} />
              <img src={myImage5} className='rounded' alt="Los Angeles" style={{ height: '30px' }} />
            </div>
          </div>
        </div>
        <div className="p-2 flex flex-wrap">
          <a href="/" className="text-white mb-1 mr-4">
            Home
          </a>
          <a href="/about-us" className="text-white mb-1 mr-4">
            Company
          </a>
          {/* Add the mb-1 class to add margin-bottom between links */}
          <a href="/contact" className="text-white mb-1 mr-4">
            Contact
          </a>
          <a href="/products" className="text-white mb-1 mr-4">
            Products
          </a>
          <a href="/flooring-services" className="text-white mb-1 mr-4">
            Services
          </a>
          <a href="/reviews" className="text-white mb-1 mr-4">
            Reviews
          </a>
          <a href="/financing" className="text-white mb-1 mr-4">
            Financing
          </a>
          <a href="/inspiration" className="text-white mb-1 mr-4">
            Inspiration
          </a>
          <a href="/free-estimate" className="text-white mb-1">
            Request
          </a>
        </div>
        <div>
          <span className="text-base font-bold tracking-wide text-white">Social</span>
          <div className="flex items-center mt-1 space-x-3">
            <a
              href="https://www.facebook.com/nicklesflooring"
              className="text-white transition-colors duration-300 hover:text-deep-purple-accent-400"
            >
              <i className="bi bi-facebook"></i>
            </a>
            <a
              href="https://www.yelp.com/biz/nickles-flooring-cassville"
              className="text-white transition-colors duration-300 hover:text-deep-purple-accent-400"
            >
              <i className="bi bi-yelp"></i>
            </a>
          </div>
        </div>
      </div>

      <div className="flex flex-col-reverse justify-between pt-5 pb-10 border-t lg:flex-row container mx-auto">
        <p className="text-sm text-white">
          Copyright © 2023  Nickle's Flooring. All rights reserved.
        </p>
        
      </div>
    </div>
  )
}

export default Footer;
