import React from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Headers from './Component/Header';
import Footer from './Component/Footer';
import Request from './Component/requestEstimate';
import s11 from './Component/s11.png';
import s12 from './Component/s12.png';

function Contact() {
    return (
        <div>
            <div><Headers /> </div>
            <section className="hero-wrap hero-wrap-2" style={{ backgroundImage: `url(${s11})` }} data-stellar-background-ratio="0.5">
                <div className="overlay" style={{ background: 'rgba(0, 0, 0, 0.3)' }}>
                    <div className="container">
                        <div className="row no-gutters slider-text align-items-end justify-content-center">
                            <div className="col-md-9 ftco-animate mb-2 text-center p-5 text-white ">
                                <h2 className=" "><div className='text-white  capitalize'>Bathroom Remodeling</div></h2>
                                <p class="mb-2 text-lg font-normal text-white lg:text-xl sm:px-16 xl:px-48 " >Wide variety of styles </p>

                                <a href='/free-estimate'> <div className='btn btn-danger '>Request an Estimate</div> </a>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            <div className='row  justify-center items-center'>
                <div className='col-md-6  '><Request /> </div>
                <div className='col-md-6 '>
                    <div className=' p-5' >
                        <h2 class=" font-bold">It’s time to get excited about bathroom remodeling</h2>
                        <div className='row'>
                            <div class="col-md-12">
                                <div class="card-box-c foo" style={{ borderRadius: '5px' }}>
                                    <div class="card-body-c align-self-center">
                                        <p class="content-c">Bathroom remodeling: it’s something every homeowner has to face at some point or another. For some, it’s not as exciting as it can be and we hope to change that by the time you’re finished reading. This project can bring great functionality and beauty to your home in so many ways.</p>

                                        <p class="content-c">
                                            Since the bathroom is a space that is used multiple times daily, and at all hours, it must function exactly as it should. However, you want it to look its best as well. You can have both and a bathroom remodeling provider can show you exactly how it’s done.											</p>

                                    </div>
                                </div>
                            </div>

                        </div>
                        <h4 class=" font-bold">Bathroom remodeling done right</h4>
                        <div className='row'>
                            <div class="col-md-12">
                                <div class="card-box-c foo" style={{ borderRadius: '5px' }}>
                                    <div class="card-body-c align-self-center">
                                        <p class="content-c">There’s likely one major reason that’s brought you to the decision that bathroom remodeling is necessary. It could be that you need a new sink, toilet, or bathtub. It could be that your faucet or handles are wearing out on the tub or sink.
                                        </p>

                                        <p class="content-c">
                                            The good news is that these, and so much more, are easy to deal with in a bathroom remodel. Not only do we cover these important areas but we can also help with lighting, fixtures, shower installations, moving things around, adding new flooring, or creating new colors and décor.
                                        </p>

                                    </div>
                                </div>
                            </div>

                        </div>
                        {/* <p class="lead">
                            <a href="/free-estimate" class="btn btn-danger btn-lg active" role="button" style={{ borderRadius: '15px' }}>Request an Estimate</a>
                        </p>       */}
                          </div>

                </div>

            </div>
            <div className='p-5'>
            <div className='row  justify-center items-center'>
                <div className='col-md-6  '><img
								src={s12}
								alt="brands"
								className="mx-auto "
							/> </div>
                <div className='col-md-6 '>
                    <div className=' mt-10 pr-[20px] p-2' >
                        <div className='row'>
                            <div class="col-md-12">
                                <div class="card-box-c foo" style={{ borderRadius: '5px' }}>
                                    <div class="card-body-c align-self-center">
                                        <p class="content-c">In bathroom remodeling, the work can be as much about making things look wonderful as it is about creating the perfect functionality. For instance, have you found there’s just not enough light for shaving or fixing your hair? Let us know, and we can create lighting that will match your needs perfectly.</p>

                                        <p class="content-c">
                                        Our bathroom remodeling services are available to you and completely personalized to your specific needs and preferences. Just let us know what’s missing, or what needs to change. No matter what you need to make your bathroom perfect again, this bathroom remodeling provider is ready to go to work for you.				</p>

                                    </div>
                                </div>
                            </div>

                        </div>
                        <h4 class=" font-bold">All your bathroom remodeling needs can be found here</h4>
                        <div className='row'>
                            <div class="col-md-12">
                                <div class="card-box-c foo" style={{ borderRadius: '5px' }}>
                                    <div class="card-body-c align-self-center">
                                        <p class="content-c">When you’re ready for bathroom remodeling, Nickle's Flooring wants you to know you can be excited about it. We offer a bathroom remodeling service and hope to make all your plans come to life after visiting our Cassville, MO showroom.
                                        </p>

                                        <p class="content-c">
                                        We’ve already done work for the residents of Cassville, MO, Shell Knob, MO, Holiday Island, AR, Golden, MO, Eagle Rock, MO, Exeter, MO, Seligman, MO, Purdy, MO, Wheaton, MO, && Washburn, MO, and we look forward to helping you with your remodeling project as well. Just stop by and let us know exactly what you need. No project is too big or too small.                                        </p>

                                    </div>
                                </div>
                            </div>

                        </div>
                        <p class="lead">
                            <a href="/free-estimate" class="btn btn-danger btn-lg active" role="button" style={{ borderRadius: '15px' }}>Request an Estimate</a>
                        </p>        </div>

                </div>

            </div>
            </div>


            <div><Footer /> </div>
        </div>
    )
}

export default Contact
