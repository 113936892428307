import React from 'react'
import Home from '../Home'
import {BrowserRouter , Routes, Route} from 'react-router-dom'
import NotFound from '../PageNotFound.jsx'
import FreeEstimate from '../FreeEstimate.jsx';

import Services from '../Services.jsx'
import Contact from '../Contact.jsx'
import Reviews from '../Reviews.jsx'
import Financing from '../Financing.jsx'
import Inspiration from '../Inspiration.jsx'
import About from '../About.jsx'
import Product from '../Product.jsx'
import Bathroomremodeling from '../bathroom-remodeling.jsx';
import Blindsnshutters from '../blinds-n-shutters.jsx';
import GlassTile from '../glasstile.jsx';
import ProductDetail from '../productdetail.jsx';
import Builderprogram from '../builder-contractor.jsx';
import Designservices from '../design-service.jsx';

const AllRoutes = () => {
  return (
    <BrowserRouter>
    <Routes>
      <Route path='/' element={<Home/>}></Route>
      {/* <Route path='/specials' element={<Specials/>}></Route> */}

      <Route  path="/products/:category/:id" element= {<ProductDetail />}/>


      <Route path='/products' element={<Product/>}></Route>
      <Route path='/products/:id' element={<GlassTile/>}></Route>
      

      <Route path='/bathroom-remodeling' element={<Bathroomremodeling />}></Route>
      <Route path='/blinds-n-shutters' element={<Blindsnshutters />}></Route>
      <Route path='/builder-n-contractor-program' element={<Builderprogram />}></Route>
      <Route path='/design-services' element={<Designservices />}></Route>



      <Route path='/flooring-services' element={<Services/>}></Route>
      <Route path='/reviews' element={<Reviews/>}></Route>
      <Route path='/financing' element={<Financing/>}></Route>
      <Route path='/inspiration' element={<Inspiration/>}></Route>
      <Route path='/about-us' element={<About/>}></Route>
      <Route path='/contact' element={<Contact/>}></Route>
      <Route path='/free-estimate' element={<FreeEstimate/>}></Route>
      
      
      <Route path="*" element={<NotFound />} />
    </Routes>
    </BrowserRouter>
  );
};

export default AllRoutes;
