import React, { useState } from 'react'
import logo from './home/logo.png';
import logo512 from './home/logo512.png';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // Import Bootstrap JS
function Header() {
	const [menuOpen, setMenuOpen] = useState(false);

	const onToggleMenu = () => {
		setMenuOpen(true);
	};
	const onCloseMenu = () => {
		setMenuOpen(false); // Close the menu
	};

	return (
		<div className='font-sans'>
			<div className='bg-[#803333] text-center p-2 text-white text-sm' style={{backgroundColor:'#803333'}}><span>WE HAVE A LARGE IN-STOCK SELECTION OF WATERPROOF FLOORING & CARPET AVAILABLE!</span></div>
			<div class="bg-white ">
				<div class="container px-2">

					<div class="flex items-center justify-between pt-4 pb-2">

						<div class="hidden sm:flex sm:items-center">
							<a href="/" class="d-block" style={{ height: '35%', width: '70%' }} ><img src={logo512} alt="Los Angeles" /> </a>						</div>

						<div className=" d-flex row justify-content-center " >
							<a href="/" class="d-block " style={{ height: '35%', width: '70%' }} ><img src={logo} alt="Los Angeles" /> </a>
							<p className='hidden sm:flex sm:font-[10px] text-center items-center justify-center'>470 State Highway 76 Cassville, MO 65625   <a href="tel:+14178472484" className='text-black font-semibold'>
                <i className="bi bi-telephone"> (417) 847-2484</i>
              </a></p>

						</div>



						<div class="hidden sm:flex sm:items-center">
							<div class="text-black  text-sm font-semibold border px-4 py-2 rounded-lg hover:text-purple-600 hover:border-purple-600" style={{backgroundColor:'#803333'}}><a href='/products' className='text-white p-3'>Products </a></div>
						</div>


					</div>
					<div class="flex items-center lg:order-2 pb-2 px-3">
						<button onClick={onToggleMenu}
							name={menuOpen ? 'close' : 'menu'}
							className="text-3xl cursor-pointer md:hidden">
							<span class="sr-only">Open main menu</span>
							<svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
							<svg class="hidden w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>

						</button>

					</div>



					<div
						className={`nav-links duration-500 md:static   text-black    left-0 ${menuOpen ? 'top-[9%] bg-white relative' : 'top-[-100%] items-center justify-content-center absolute'
							} md:w-auto w-full flex  `} 
					>
						<ul className="flex md:flex-row flex-col md:items-center  md:justify-content-center p-2 border-y-2 border-slate-700 font-sans">

						<li className={`md:hidden ${menuOpen ? 'md:hidden' : 'md:hidden'}`}>
								<button class="text-black   font-semibold hover:text-purple-600 " onClick={onCloseMenu}>
								<i class="bi bi-x-square-fill"></i>
								</button>
							</li>
							{/* <li>
								<a href="/specials" class="text-black   btn px-3">Specials</a>
							</li> */}
							<li className="nav-item">
								<div className="btn-group dropend">
									<button
										type="button"
										className="btn  dropdown-toggle font-semibold text-black"
										data-bs-toggle="dropdown" 
										aria-expanded="false"
									>
										Products
									</button>
									<ul className="dropdown-menu">
										<li>
											<a href="/products" class=" dropdown-item hover:text-green-900 ">All Products</a>
										</li>
										<li>
											<a href="/products/carpet" class=" dropdown-item hover:text-green-900 ">Carpet</a>
										</li>
										<li>
											<a href="/products/hardwood" class=" dropdown-item hover:text-green-900 ">Hardwood</a>
										</li>
										<li>
											<a href="/products/naturalstone" class=" dropdown-item hover:text-green-900 ">Natural Stone</a>
										</li>
										<li>
											<a href="/products/Tile" class=" dropdown-item hover:text-green-900 ">Tile</a>
										</li>
										<li>
											<a href="/products/luxuryvinyl" class=" dropdown-item hover:text-green-900 ">luxury Vinyl</a>
										</li>
										<li>
											<a href="/products/vinyl" class=" dropdown-item hover:text-green-900 ">Vinyl</a>
										</li>
										<li>
											<a href="/products/glasstile" class=" dropdown-item hover:text-green-900 ">Glass Tile</a>
										</li>
										<li>
											<a href="/products/metaltile" class=" dropdown-item hover:text-red-700 ">Metal Tile</a>
										</li>
										
										
									</ul>
								</div>
							</li>
							<li className="nav-item">
								<div className="btn-group dropend">
									<button
										type="button"
										className="btn  dropdown-toggle font-semibold text-black"
										data-bs-toggle="dropdown" 
										aria-expanded="false"
									>
										Services
									</button>
									<ul className="dropdown-menu">
										<li>
											<a href="/flooring-services" class=" dropdown-item hover:text-green-900 ">All Services</a>
										</li>
										<li>
											<a href="/bathroom-remodeling" class=" dropdown-item hover:text-green-900 ">Bathroom Remodeling</a>
										</li>
										<li>
											<a href="/blinds-n-shutters" class=" dropdown-item hover:text-green-900 ">Blinds & Shutters</a>
										</li>
										<li>
											<a href="/builder-n-contractor-program" class=" dropdown-item hover:text-green-900 ">Builder & Contractor Program</a>
										</li>
										<li>
											<a href="/design-services" class=" dropdown-item hover:text-green-900 ">Design Services</a>
										</li>
									</ul>
								</div>
							</li>
							<li>
								<a href="/reviews" class="text-black btn px-3">Reviews</a>
							</li>
							<li>
								<a href="/financing" class="text-black  btn px-3">Financing</a>
							</li>
							<li>
								<a href="/inspiration" class="text-black  btn px-3">Inspiration</a>
							</li>
							<li>
								<a href="/about-us" class="text-black  btn px-3">About</a>
							</li>
							<li>
								<a href="/contact" class="text-black  btn px-3">Contact Us</a>
							</li>
							<li className={`md:hidden ${menuOpen ? 'md:hidden' : 'md:hidden'}`}>
										<a href="/free-estimate" class=" text-black  btn px-3">Request an Estimate</a>

							</li>


						</ul>
					</div>
				</div>




			</div>


		</div>
	)
}

export default Header
