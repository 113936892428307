import React from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Headers from './Component/Header';
import Footer from './Component/Footer';
import Request from './Component/requestEstimate';
import s11 from './Component/s13.png';
import s14 from './Component/s14.png';

function Contact() {
    return (
        <div>
            <div><Headers /> </div>
            <section className="hero-wrap hero-wrap-2" style={{ backgroundImage: `url(${s11})` }} data-stellar-background-ratio="0.5">
                <div className="overlay" style={{ background: 'rgba(0, 0, 0, 0.3)' }}>
                    <div className="container">
                        <div className="row no-gutters slider-text align-items-end justify-content-center">
                            <div className="col-md-9 ftco-animate mb-2 text-center p-5 text-white ">
                                <h2 className=" "><div className='text-white  capitalize'>Blinds & Shutters</div></h2>
                                <p class="mb-2 text-lg font-normal text-white lg:text-xl sm:px-16 xl:px-48 " >Wide variety of styles </p>

                                <a href='/free-estimate'> <div className='btn btn-danger '>Request an Estimate</div> </a>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
           
            <div className='p-5'>
            <div className='row  justify-center items-center'>
                <div className='col-md-6  '><Request /> </div> 
                <div className='col-md-6 '>
                    <div className=' mt-10 pr-[20px] p-2' >
                        <h2 class=" font-bold">Is it time to upgrade your windows?</h2>
                        <p class=" font-semibold">Featuring eclipse shutters and blinds, wood, woven, soft shades, vertical, pleated, solar</p>
                        <img
								src={s14}
								alt="brands"
								className="mx-auto "
							/>
                        <p class="lead py-3">
                            <a href="/free-estimate" class="btn btn-danger btn-lg active" role="button" style={{ borderRadius: '15px' }}>Request an Estimate</a>
                        </p>        </div>

                </div>

            </div>
            </div>


            <div><Footer /> </div>
        </div>
    )
}

export default Contact
