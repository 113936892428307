import React from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Headers from './Component/Header';
import Footer from './Component/Footer';
import myImage from './Component/google-review.png';
function Reviews() {
  return (
    <div>
      <div><Headers /> </div>
      <div className='container'>
        <div className='text-center font-bold '>
          <span style={{ fontSize: '35px' }}>Reviews</span> <br />
          <small className='justify'> HOW DID WE DO? LEAVE US A REVIEW BELOW!</small>
          <div class="flex items-center justify-center ">
            <a href="https://www.google.com/maps/place/Nickle's+Flooring/@36.6728458,-93.8654706,15z/data=!4m8!3m7!1s0x87c8d0f8fb4045b5:0x108a292f87bf7f6c!8m2!3d36.6728458!4d-93.8654706!9m1!1b1!16s%2Fg%2F1trqhrdd?entry=ttu" target="_blank" rel="noreferrer">
              <img src={myImage} alt="Los Angeles" />
            </a>
          </div>
        </div>

        <section >
          <div>



          </div>
        </section >
      </div>
      <div><Footer /> </div>
    </div>
  )
}

export default Reviews
